import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSession from 'vue-session'
import axios from 'axios';
import vueCustomElement from 'vue-custom-element'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VueSession)
Vue.config.productionTip = false
const ComponentContext = require.context('./components/', true, /\.vue$/i, 'lazy');

ComponentContext.keys().forEach((componentFilePath) => {

    const componentName = componentFilePath.split('/').pop().split('.')[0];
    Vue.component(componentName, () => ComponentContext(componentFilePath));

});


Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(value).format("DD.MM.YYYY");
    }
});

Vue.filter('formatDateShort', function (value) {
    if (value) {
        return moment(value).format("DD.MM");
    }
});
Vue.filter('formatDateTime', function (value) {
    if (value) {
        return moment(value).format("DD.MM.YYYY HH:mm");
    }
});
Vue.filter('formatTime', function (value) {
    if (value) {
        return moment(value).format("HH:mm");
    }
});
import 'document-register-element/build/document-register-element'
// vue-custom-element by karol-f
Vue.use(vueCustomElement)
axios.defaults.headers.common['Content-Type'] = "application/json";
Vue.config.productionTip = false


App.router = router
import config from "@/config/config.js";
App._config = config;
window.GLOBALVUE = Vue.customElement('ozg-summary', App)

window.GLOBALCONFIG = config
window.GLOBALAXIOS = axios;